<template>
  <div
    class="max-height-0 min-height-inherit position-sticky top-70 force-width-400 d-flex flex-column"
  >
    <div style="width: 360px" class="mt-4">
      <v-menu offset-y v-model="searchDropdown">
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="searchKey"
            hide-details
            placeholder="Search"
            solo
            flat
            dense
            append-icon="mdi-magnify"
            class="mt-sm-1 mt-md-1 mt-lg-1 mt-xl-1"
            elevation="0"
            v-bind="attrs"
            v-on="on"
            background-color="#D6CDF7"
            autocomplete="off"
            @keyup.enter="onSearch"
          />
        </template>
        <div style="max-height: 60vh">
          <v-list>
            <v-list-item
              v-for="(item, index) in recentSearches"
              :key="index"
              class="list-item"
            >
              <v-list-item-title
                @click="onSelectKey(item.key)"
                style="display: flex; justify-content: space-between; align-items: center"
              >
                {{ item.key }}
                <v-btn icon @click.stop="onDeleteKey(item)"
                  ><v-icon>mdi-close</v-icon></v-btn
                >
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="onSearch" class="list-item">
              <v-list-item-title>
                <v-icon class="mr-2">mdi-magnify</v-icon>Search
                {{ searchKey ? `for "${searchKey}"` : "" }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-menu>
    </div>
    <div class="overflow-hidden hover-scroll flex-grow">
      <div class="d-flex flex-column mb-4" style="width: 360px">
        <agency-need-card
          :need="need"
          v-for="need in needs"
          :key="need._id"
          :hideButtons="true"
          class="mt-4"
          :onClicked="onSelected"
        />
        <div v-if="!needs.length" class="mt-10 mx-10">
          No needs found.
          <div v-if="profileType == 'Agency'">
            Please create new needs.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AgencyNeedCard from "../agency/needs/AgencyNeedCard.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { AgencyNeedCard },
  data() {
    return {
      searchDropdown: false,
      searchKey: "",
      recentSearches: [],
    };
  },
  methods: {
    ...mapActions({
      getSearchHistory: "search/getSearchHistory",
      deleteKey: "search/deleteKey",
    }),
    onSelected(need) {
      if (this.$route.query._id != need) {
        this.$router.replace({ query: { _id: need } });
      }
    },
    onSearch() {
      this.searchDropdown = false;
      if (this.searchKey != this.$route.query.search) {
        this.$router.replace({ query: { search: this.searchKey } });
      }
    },
    onSelectKey(key) {
      this.searchKey = key;
      this.searchDropdown = false;
      this.onSearch();
    },
    onDeleteKey(key) {
      this.deleteKey({ _id: key._id })
        .then((res) => {
          this.recentSearches = this.recentSearches.filter(
            (r) => r._id != res._id
          );
        })
        .catch((error) => {
          console.log(error.response.data.message);
        });
    },
  },
  watch: {
    searchDropdown(newValue) {
      if (newValue) {
        this.getSearchHistory({ type: "Need" })
          .then((res) => {
            this.recentSearches = res;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    "$route.query.search": function(newValue) {
      this.searchKey = newValue;
    },
  },
  computed: {
    ...mapGetters({
      needs: "need/getAllNeeds",
      profileType: "auth/getType",
    }),
  },
  mounted() {
    this.searchKey = this.$route.query.search;
  },
};
</script>
<style scoped></style>
