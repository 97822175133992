<template>
  <div class="feed-body">
    <div class="feed-contents">
      <GmapMap
        :center="center"
        :zoom="13"
        map-type-id="terrain"
        class="map"
        v-if="center"
        :options="
          $vuetify.breakpoint.xs
            ? {
                fullscreenControl: false,
              }
            : null
        "
      >
        <GmapMarker
          :key="index"
          v-for="(m, index) in markers"
          :position="m.position"
          :clickable="true"
          :icon="m.icon"
          @click="onClickedMarker(m, index)"
        >
          <GmapInfoWindow
            :opened.sync="m.ifw"
            :options="{
              pixelOffset: { width: 0, height: 0 },
              content: m.template,
            }"
            @closeclick="m.ifw = false"
          />
        </GmapMarker>
      </GmapMap>
      <v-btn
        style="position: absolute; top: 10px; right: 10px; padding: 0; min-width: 50px; height: 40px;"
        tile
        @click="openRightSection"
        v-if="$vuetify.breakpoint.xs"
      >
        <v-icon style="margin-top: 2.5px;">mdi-menu</v-icon>
      </v-btn>
    </div>
    <v-dialog v-model="rightSectionDialog" v-if="rightSectionDialog">
      <v-card>
        <v-card-text class="pt-4 pb-8">
          <agency-need-card
            :need="need"
            v-for="need in needs"
            :key="need._id"
            :hideButtons="true"
            class="mt-4"
            :onClicked="onSelected"
          />
          <div v-if="!needs.length" class="mt-10 mx-10">
            No needs found.
            <div v-if="profileType == 'Agency'">
              Please create new needs.
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import AgencyNeedCard from "../agency/needs/AgencyNeedCard.vue";
export default {
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      loadCompleted: -1,
      loadingMore: false,
      center: { lat: 42.2788668, lng: -71.4206997 },
      markers: [],
      rightSectionDialog: false,
    };
  },
  methods: {
    ...mapActions({
      fetchAllNeeds: "need/fetchAllNeeds",
      getLocation: "need/getLocation",
      getCurrentWeather: "weather/getCurrentWeather",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    getLocationFromAddress() {
      var address = `${this.profile.address}${
        this.profile.address2 ? " " + this.profile.address2 : ""
      }, ${this.profile.city}, ${this.profile.state} ${this.profile.zip}`;
      this.getLocation(address).then((res) => {
        this.center = res;
      });
    },
    onClickedMarker(m, index) {
      this.markers = this.markers.map((m) => {
        m.ifw = false;
        return m;
      });
      this.markers[index].ifw = true;
    },
    async loadMarkers() {
      this.markers = [];
      for (let i = 0; i < this.needs.length; i++) {
        try {
          var need = this.needs[i];
          var template = "<h2 style='color:#482684;'>" + need.title + "</h2>";
          var icon = { url: require("@/assets/marker_need.png") };
          if (need.agency_name)
            template +=
              "<br><h4 style='color:#8e5ee3;'>" + need.agency_name + "</h4>";
          if (need.duration)
            template +=
              "<br><h4 style='color:#8e5ee3;'>" + need.duration + "</h4>";
          template += `<br><a href='/needs/details?_id=${need._id}' target="_blank">View details</a>`;
          var lc = await this.getLocation(
            `${need.address ? need.address : ""}${
              need.address2 ? " " + need.address2 + "," : ""
            } ${need.city ? need.city + "," : ""} ${
              need.state ? need.state : ""
            } ${need.zip ? need.zip : ""}`.trim()
          );
          this.markers.push({
            position: lc,
            ifw: false,
            label: need.title,
            template: `<b>${template}</b>`,
            icon,
          });
        } catch (error) {
          console.log(error);
        }
      }
      this.mergeMarkers();
      for (let i = 0; i < this.markers.length; i++) {
        try {
          const weather = await this.getCurrentWeather({
            unit: this.unit,
            ...this.markers[i].position,
          });
          let weatherTemplate = `
          <div style="display: flex; flex-direction: row; align-items: center;background-color: #f5faff; border-radius: 10px; padding-right: 10px; padding-top: 5px; padding-bottom: 5px">
            <img src="${this.forecastIcon(
              weather.weather[0]["icon"]
            )}" style="width: 72px; height: 72px"/>
            <div style="display: flex; flex-direction: column; margin-left: 10px;">
              <div style="font-weight: bold; color: #0A5B8A; font-size: 16px;">${
                weather.main.temp
              }&deg;${this.unit == "imperial" ? "F" : "C"}</div>
              <div style="display: flex; flex-direction: row; margin-top: 5px; margin-bottom: 5px;">
                <span>${weather.main.temp_max}&deg;${
            this.unit == "imperial" ? "F" : "C"
          }</span>
                <span style="margin-left: 10px; color: #919191">${
                  weather.main.temp_min
                }&deg;${this.unit == "imperial" ? "F" : "C"}</span>
              </div>
              <div style="font-weight: bold; color: black">
                ${this.getFirstCap(weather.weather[0].description)}
                </div>
            </div>
          </div>`;
          this.markers[i].template += "<br><br><br>" + weatherTemplate;
        } catch (error) {
          console.log(error);
        }
      }
    },
    mergeMarkers() {
      var index = 0;
      while (index < this.markers.length) {
        var ok = true;
        for (var i = index + 1; i < this.markers.length; i++) {
          if (
            Math.abs(
              this.markers[i].position.lat - this.markers[index].position.lat
            ) < 0.000001 &&
            Math.abs(
              this.markers[i].position.lng - this.markers[index].position.lng
            ) < 0.000001
          ) {
            this.markers[index].template +=
              "<br><br><br>" + this.markers[i].template;
            ok = false;
            this.markers.splice(i, 1);
            break;
          }
        }
        if (ok) index++;
      }
    },
    forecastIcon(param) {
      let icon = "";
      try {
        icon = param || "01d";
      } catch {
        icon = "01d";
      }
      return require(`@/assets/weather/${icon}.png`);
    },
    getFirstCap(res) {
      let desc = res ? res : "";
      return desc.charAt(0).toUpperCase() + desc.slice(1);
    },
    openRightSection() {
      this.rightSectionDialog = true;
    },
    onSelected(need) {
      if (this.$route.query._id != need) {
        this.$router.replace({ query: { _id: need } });
      }
      this.rightSectionDialog = false;
    },
  },
  computed: {
    ...mapGetters({
      profileType: "auth/getType",
      profile: "auth/getProfile",
      needs: "need/getAllNeeds",
      unit: "usersetting/getUnit",
    }),
  },
  watch: {
    profile(newValue) {
      if (newValue.address) {
        this.getLocationFromAddress();
      }
    },
    "$route.query._id": function(newValue) {
      this.markers = this.markers.map((m) => {
        if (m.template.includes(newValue)) {
          m.ifw = true;
        } else {
          m.ifw = false;
        }
        return m;
      });
    },
    "$route.query.search": function(newValue) {
      this.fetchAllNeeds({ search: newValue })
        .then(() => {
          this.loading = false;
          this.loadMarkers();
        })
        .catch((error) => {
          this.loading = false;
          if (error.response == undefined) {
            console.log(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });
    },
  },
  mounted() {
    if (this.profile.address) {
      this.getLocationFromAddress();
    }
    this.loading = true;
    this.loadCompleted = 0;
    this.fetchAllNeeds({ search: this.$route.query.search })
      .then(() => {
        this.loading = false;
        this.loadMarkers();
      })
      .catch((error) => {
        this.loading = false;
        if (error.response == undefined) {
          console.log(error);
        } else {
          this.showErrorMessage(error.response.data.message);
        }
      });
  },
  components: { AgencyNeedCard },
};
</script>
<style scoped>
@media (max-width: 600px) {
  div :deep(.v-dialog) {
    margin: 0;
    padding-top: 12px;
    padding-bottom: 12px;
  }
}
@media (min-width: 600px) {
  .v-dialog__content {
    z-index: 2000001 !important;
  }
}
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.feed-contents {
  width: 100%;
  height: 100%;
  /* max-width: 650px; */
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
@media (max-width: 600px) {
  .feed-contents {
    padding-bottom: 56px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 0px;
    position: relative;
  }
}
.map {
  width: 100%;
  height: 100%;
}
</style>
